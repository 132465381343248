@import url("css/bootstrap.min.css");

html,
body,
ul {
  margin: 0;
  padding: 0; }

body {
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}


img {
  display: block;
  max-width: 100%; }

ul {
  list-style: none; }

a,
button {
  transition: all .4s;
  cursor: pointer; }
button {
  display: block;
}
button:focus {
  outline: none; }

a:hover {
  text-decoration: none; }

$c1: #df0523;

section:not(.fs-section) {
  padding: 70px 0;
}


.page-header {
  position: relative;
  background-color: #efefef;
}

.logo-wrapper {
  position: absolute;

  padding: 85px 10px 10px;
  background-color: #fff;
}


.header-inner,
.main-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center; }

.main-menu {
  margin-left: 260px;
  margin-right: auto;
}
.nav-item {
  position: relative; }

.menu-link {
  padding: 15px 20px;
  margin-right: 25px;
  color: #000;
  line-height: 1;
  border-bottom: 5px solid transparent;
}
.menu-link:last-child {
  margin-right: 0; }
.menu-link:hover {
  color: #000;
  background-color: #fff;
  border-bottom-color: $c1;
}
.menu-link:hover + .submenu {
  display: block; }

.submenu {
  display: none;
  position: absolute;
  z-index: 5;
  left: 0;
  top: 100%;
  width: 230px;
  color: #fff;
  background-color: $c1;
  padding: 15px; }

.phones {

}
.phone {
  display: block;
  color: #000;
  text-align: right;
  &:hover {
    color: $c1;
  }


}

.fs-section {
  padding: 150px 0;
  background: url(img/bg1.png) center top   no-repeat;

}
.text-block {
  margin-top: 40px;
}
.main-header {
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 50px;
}
.main-subheader {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.2;

  span {
    color: $c1;
  }
}
.call-btn {
  padding: 20px 15px;
  background-color: $c1;
  font-weight: 800;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,.7);
  border: 2px solid transparent;

  &:hover {
    background-color: transparent;
    color: $c1;
    border-color: $c1;
    box-shadow: none;
  }
}
.fs-advantages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  margin-left: auto;
}
.advant-header {
  width: 100px;
  margin: auto;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  border-bottom: 5px solid $c1;
  span {
    font-weight: 300;
    font-size: 14px;
  }
}
.advant-text {
  text-align: center;
  color: $c1;
}

.fs-btn {
  margin-top: 10px;
  width: 360px;
}


.section-header {
  font-size: 36px;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  color: #1f191a;
  line-height: 1.2;
}
.repair-subheader {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: $c1;
}
.rep-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rep-text {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.rep-img-wrapper {
  height: 200px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.rep-btn {
  width: 200px;
  margin: 0 auto;
  padding: 15px;
}
section.items-section {
  padding: 50px 0 0;
  background: url(img/bg2.png) center bottom no-repeat;
}
.items-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.items-header {
  margin-bottom: 50px;
  text-align: left;
  span {
    font-weight: 700;
  }
}
.items-subheader {

  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;

  span {
    display: block;
    color: $c1;
  }
}
.br-section-header {
  margin-bottom: 50px;
}
.br-card {
  margin-bottom: 25px;
  display: flex;
  align-items: center;

  &.br-left {
    justify-content: flex-end;
    .br-text {
      margin-right: 30px;
      text-align: right;
    }
  }
  &.br-right {
    justify-content: flex-start;
    .br-text {
      order: 2;
      margin-left: 30px;
      text-align: left;
    }
  }
}
.br-text {
  font-weight: 700;

  span {
    color: $c1;
  }
}
.br-img {
  flex-shrink: 0;
}
section.go-section {
  padding: 120px 0;
  background: url(img/go.png) left center / 530px no-repeat,
              url(img/bg3.png) center top no-repeat;
}
.go-header {
  margin-bottom: 30px;
  text-align: left;
  span {
    display: block;
    font-weight: 700;
  }
}
.go-subheader {
  font-size: 18px;
  font-weight: 700;
  color: $c1;
}
.form-section {
  background-color: $c1;
}
.offer-wrapper,
.form-wrapper {
  display: flex;
  align-items: center;
}
.offer-header,
.form-header {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 24px;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  line-height: 1.3;

  span {
    display: block;
    font-weight: 700;
  }
}
.form {
  display: flex;
  align-items: center;
}
.form-input {
  margin-left: 30px;
  width: 360px;
  padding: 10px 15px;
  color: #e1e1e1;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  border: none;
  outline: none;
}
::-webkit-input-placeholder {opacity: .2;}
::-moz-placeholder          {opacity: .2;}
:-ms-input-placeholder      {opacity: .2;}

.form-submit {
  width: 200px;
  margin-left: 30px;
  padding: 10px 15px;
  background-color: #e1e1e1;
  color: $c1;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border: none;
}
section.work-section {
  padding-bottom: 150px;
  background: url(img/shadow.png) center bottom 50px no-repeat;
}
.work-header {
  margin-bottom: 50px;
  span {
    font-weight: 700;
  }
}
.work-img {
  margin-left: 50px;
}
.timer-section {
  background-image: linear-gradient(to bottom, #262626, #000);
}
.timer-wrapper {
  margin: 0 100px;
}
.timer {

  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}
.time-count {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}
.timer-separator {
  margin: 0 10px;
  font-size: 40px;
  line-height: 1;
  align-items: flex-start;
}
.time-def {
  display: block;
  font-size: 14px;
  text-align: center;
}
.timer-header {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.offer-btn {
  padding: 10px;
  width: 200px;
  flex-shrink: 0;
  font-size: 14px;
}
.advantages {
  padding: 80px 0;
}
.advantage {
  display: flex;
  align-items: flex-start;
  justify-content: center;

}
.advantage-header {
  padding-left: 25px;
  background: url(img/pointer.png) left 10px no-repeat;
  line-height: 1;
  font-weight: 700;
  font-size: 48px;

  span {
    font-size: 14px;
    font-weight: 300;
    line-height: .1;
  }
}
.advantage-text {
  padding-left: 10px;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  border-left: 1px solid $c1;
}
.price-section {
  background-color: #f6f6f6;
}
.price-main-header {
  margin-bottom: 0;
}
.price-subheader {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: $c1;

  span {
    display: inline-block;
    line-height: 1;
    position: relative;
    font-weight: 300;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $c1;
    }
  }
}
.price-table {
  display: flex;
  margin: 50px 0;
}
.price {
  &:not(:last-child) .price-top {
    border-right: 1px solid #383838;
  }

  &:nth-child(3),
  &:nth-child(4) {

    .price-top {
      padding: 15px 30px;
    }
  }

  &:nth-child(odd) {
    .price-bottom {
      background-color: #fff;
    }

  }
  &:nth-child(even) {
    background-color: #e3e3e3;
  }
}
.price-top {
  padding: 15px;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  text-align: center;


}
.price-bottom {
  padding: 15px;
  text-align: center;
  color: $c1;
  font-size: 14px;
  font-weight: 700;
  span {
    font-size: 24px;
  }
}
.price-btn {
  display: block;
  padding: 15px;
  margin: 0 auto;
  width: 270px;
}
.stories-header {
  margin-bottom: 50px;
}
.stories-slider {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.stories-slide {
  padding: 40px 20px;
  display: flex;
  align-items: flex-start;
  background-color: #f6f6f6;
}
.slider-img-wrapper {
  margin-right: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.diag-item {
  font-size: 14px;
  font-weight: 700;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.diag-header {
  color: $c1;
}
.diag-text {
  color: #1f191a;
}
.stories-slide {
  width: 43%;
}
section.map-section {
  padding-top: 25px;
}
.contacts-header {
  padding-bottom: 5px;
  display: table;
  margin-bottom: 25px;
  border-bottom: 1px solid $c1;
}
.contacts-address {
  font-size: 18px;
  color: #1f191a;
  font-weight: 300;
}
.contacts-phones {
  margin: 25px 0;
}
.contacts-phone {
  display: block;
  font-weight: 700;
  color: #1f191a;
  font-size: 18px;

  &:hover {
    color: $c1;
  }
}
.map-btn {
  width: 290px;
  padding: 15px;
}
.work-mob,
.work-mid {
  display: none;
}
@media screen and (max-width: 1199px) {
  .main-header {
    font-size: 46px;
  }
  .fs-img {
    max-width: 400px;
  }
  .work-img {
    margin-left: 0;
  }
  .offer-header span, .form-header span {
    display: inline;
  }
  .price-table {
    flex-wrap: wrap;
  }
  .price {
    width: 33.3%;
  }
  .price:nth-child(3) .price-top br {
    display: none;
  }
  .stories-slide {
    width: 48%;
  }

}
@media screen and (max-width: 991px) {
  .offer-wrapper,
  .form-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .offer-header, .form-header {
    margin-bottom: 20px;
  }
  .offer-btn {
    margin-top: 20px;
  }
  .advantage-header {
    width: 110px;
  }
  .advantage {
    width: 360px;
    margin: 0 auto 20px;
    justify-content: flex-start;
  }
  .price {
    display: flex;
    flex-direction: column;
  }
  .price-top {
    flex-grow: 1;
  }
  .stories-slider {
    flex-direction: column;
    align-items: center;
  }
  .stories-slide {
    width: 70%;
    margin-bottom: 25px;
  }
  .contacts-header {
    margin: 0 auto 25px;
  }
  .contacts-address {
    text-align: center;
  }
  .contacts-phone {
    display: table;
    margin: 0 auto;
  }
  .map-btn {
    margin: 0 auto 25px;
  }
  section.map-section {
    padding-top: 0;
  }
  .work-img {
    display: none;
  }
  .work-mid {
    display: block;
    margin: auto;
  }
  .main-menu {
    display: none;
  }
  .fs-section {
    background: url(img/bg1-mid.png) center top no-repeat;
  }
  .text-block {
    padding: 40px 25px;
    background-color: rgba(255,255,255,.7);
  }
  .main-subheader {
    display: none;
  }
  .main-header {
    margin-bottom: 20px;
    text-align: center;
  }
  .fs-img {
    margin: 10px auto;
  }
  .fs-advantages {
    margin: auto;
  }
  .fs-btn {
    margin: 50px auto 0;
  }
  .repair-subheader {
    margin-bottom: 20px;
  }
  .items-col {
    order: -1;
  }
  section.items-section {
    padding: 50px 0;
    background: url(img/bg2.png) center bottom / cover no-repeat;
  }
  .items-img {
    margin-top: 25px;
  }
  section.go-section {
    padding: 50px 0 350px;
    background: url(img/go.png) center bottom 50px/530px no-repeat, url(img/bg3.png) center top / cover no-repeat;
  }
  .logo-wrapper {
    padding: 10px;
    position: static;
  }
  .br-card {
    flex-direction: column;
    align-items: center;
  }
  .br-img {
    order: -1;
  }
  .br-card.br-right .br-text,
  .br-card.br-left .br-text {
    margin: 10px 0 0 0;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .fs-section {
    padding-top: 250px;
    padding-bottom: 50px;
    background: url(img/bg1-mid.png) center top / cover no-repeat;
  }
  .main-header {
    font-size: 40px;
  }
  .fs-img {
    max-width: 300px;
  }
  .text-block {
    padding: 20px;
    margin-top: 100px;
  }
  .advant-header {
    width: 85px;
    font-size: 42px;
    text-align: center;
  }
  .offer-header,
  .form-header {
    text-align: center;
  }
  .price {
    width: 50%;
  }
  .stories-slide {
    width: 100%;
  }
  .work-mid {
    display: none;
  }
  .work-mob {
    display: block;
    margin: auto;
  }
  .form {
    flex-direction: column;
    align-items: center;
  }
  .form-input {
    margin-left: 0;
  }
  .form-submit {
    margin-left: 0;
    margin-top: 20px;
  }
  .section-header {
    font-size: 30px;
  }
  .text-block {
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }
    &::before {
      left: -15px;
    }
    &::after {
      right: -15px;
    }
  }
  .fs-section {
    background: url(img/bg1-mob.png) center top / 100% no-repeat;
  }
  section.go-section {
    background: url(img/go.png) center bottom 30px/600px no-repeat, url(img/bg3.png) center top/cover no-repeat;
  }
  .advantages,
  section:not(.fs-section) {
    padding: 30px 0;
  }
  .price  {
    width: 100%;
  }
  .price:nth-child(even) {
    background-color: #fff;
  }
  .price-top br {
    display: none;
  }
  .advantage-text {
    width: 60%;
  }
  .advantage-header {
    width: 40%;
  }
  .stories-slider {
    margin: 0 -15px;
  }
  .stories-slide {
    margin-bottom: 5px;
  }
  .offer-header, .form-header {
    font-size: 25px;
  }
  .form-header span {
    display: block;
  }
  section.go-section {
    padding-bottom: 350px;
  }
}
@media screen and (max-width: 480px) {
  .items-img {
    max-width: 200px;
  }
  .text-block {
    background-color: rgba(255, 255, 255, 0.8);

    &::before,
    &::after {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  .phone {
    font-size: 18px;
    font-weight: 700;
  }
  .logo-wrapper {
    margin-left: -15px;
    width: 50%;
  }
  .main-header {
    font-size: 36px;
  }
  .advant-text {
    font-size: 14px;
  }
  .advant-header {
    font-size: 38px;
  }
  .fs-btn {
    width: 100%;
  }
  .rep-img-wrapper {
    height: auto;
  }
  section.work-section {
    background: none;
  }
  .price-btn {
    padding: 20px 15px;
    width: 100%;
  }
  .price-table {
    margin-bottom: 20px;
  }
  .sec-rep-card {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #bab5b5;
  }
  .rep-card {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
  .items-header {
    margin-top: 30px;
  }
  .map-btn,
  .form,
  .form-input {
    width: 100%;
  }
  .stories-slide {
    position: relative;
  }
  .slider-img-wrapper {
    position: absolute;
    right: 20px;
    top: 40px;
    margin-right: 0;
  }
  .text-block {
    padding: 20px 0;
  }
}
@media screen and (max-width: 380px) {
  .section-header {
    font-size: 25px;
  }
  .advantage-text {
    font-size: 14px;

    br {
      display: none;
    }
  }
  .advantage-header {
    font-size: 30px;
  }
  .go-subheader {
    font-size: 16px;
  }
  .fs-section {
    padding-top: 200px;
  }
  .advantage {
    width: 100%;
  }
  .br-text {
    font-size: 14px;
  }
  .fs-img {
    max-width: 100%;
  }
}